import ExperienceContent from "./ExperienceContent";

export const experienceData = [
  {
    id: "effectus",
    title: "Effectus Software",
    content: (
      <ExperienceContent
        company="Effectus Software"
        companyUrl="https://www.effectussoftware.com/"
        title={"Effectus Software"}
        jobTitle={"Frontend Developer"}
        date={"March 2022 - Present"}
        descriptions={[
          "Effectus Software offers software development of high-quality web and mobile applications to meet the client's unique demands.",
          "My responsibilities include: write and maintain high-quality code, troubleshoot issues, and collaborate with other team members to deliver projects on time and within budget.",
          "I have further developed my communication skills by participating actively in project meetings and communicating technical concepts to non-technical stakeholders. Additionally, I have improved my planning and organizational skills by prioritizing tasks effectively.",
          "I am frequently sought out by my colleagues for technical expertise and guidance, and I have also been able to help other team members improve their skills and knowledge",
          "Tech Stack: React js, React Native, Redux, GraphQL, Typescript, AWS, CSS, Bootstrap, GitLab",
        ]}
      />
    ),
  },
  {
    id: "global",
    title: "GlobalUy",
    content: (
      <ExperienceContent
        company="GlobalUy"
        companyUrl="https://globaluy.com/"
        title={"GlobalUy"}
        jobTitle={"Frontend Developer"}
        date={"January 2021 - February 2022"}
        descriptions={[
          "At GlobalUy, we provided customized software solutions, leveraging the latest technologies to deliver high-performance web and mobile applications.",
          "I was responsible for developing and maintaining code for web and mobile applications. My duties included troubleshooting issues and collaborating with team members.  I also played a key role in the development of user interfaces and worked closely with designers to ensure that the final product met the highest standards of quality and usability.",
          "In addition to my technical skills, I also brought a strong set of soft skills to my role at GlobalUy. I'm a team player, always willing to collaborate and help my colleagues to achieve our shared goals. I'm also organized and detail-oriented, ensuring that my work is always of the highest quality and delivered on time.",
          "Tech Stack: React js, React Native, Redux, Typescript, AWS, MySQL, CSS, Styled Components, Github, Bitbucket",
        ]}
      />
    ),
  },
  {
    id: "ascentis",
    title: "Ascentis HR",
    content: (
      <ExperienceContent
        company="Ascentis HR"
        companyUrl="https://www.ukg.com/ascentis"
        title={"Ascentis HR"}
        jobTitle={"Automation / Frontend Developer"}
        date={"December 2019 - January 2021"}
        descriptions={[
          "Ascentis offers a cloud-based human capital management (HCM) suite designed for midsize companies of all industries. It includes HR and benefits, recruiting and onboarding, talent management, time and attendance, reporting and payroll.",
          "I worked on the Reporting module, which provides users with the ability to generate custom reports that help them make informed decisions about their human resources management.",
          "I created reusable components and custom reports that help them make informed decisions about their human resources management. Also write automated tests to ensure the quality and reliability of the reporting module.",
          "Tech Stack: Selenium, Robot Framework, React js, Redux, Javascript, MySQL, CSS, Bitbucket",
        ]}
      />
    ),
  },
  {
    id: "switch",
    title: "Switch Software Solutions",
    content: (
      <ExperienceContent
        company="Switch Software Solutions"
        companyUrl="https://switchsoftware.us/"
        jobTitle={"FullStack Developer"}
        date={"February 2019 - December 2019"}
        descriptions={[
          "Switch Software Solutions is a software development company that provide custom web and mobile applications for businesses of all sizes. Our experienced team of developers utilized the latest technologies to deliver high-quality solutions that met the unique needs of our clients",
          "As my first experience as a developer, throughout the software development lifecycle, I learned how to write efficient code that met the requirements of each project. I worked closely with senior developers to learn new technologies and best practices, and to develop my skills in areas such as frontend and backend development, database management, and software testing",
          "I gained invaluable experience while building my first app from scratch. In addition to technical skills, I also developed a strong understanding of project management and collaboration. I participated in daily stand-up meetings, where I learned how to effectively communicate with team members and report on my progress.",
          "Tech Stack: React js, Redux, Javascript, HTML, CSS, PostgreSQL, Bitbucket",
        ]}
      />
    ),
  },
];
