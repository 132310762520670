import React from "react";

import { skillsData } from "./skillsData";
import * as Styled from "./styles";

const Skills = () => {
  const Skill = ({ name, icon }) => (
    <Styled.Skill>
      {icon}
      {name}
    </Styled.Skill>
  );

  return (
    <Styled.SkillsContainer>
      {skillsData.map(({ name, icon }) => (
        <Skill key={name} name={name} icon={icon} />
      ))}
    </Styled.SkillsContainer>
  );
};

export default Skills;
