import styled, { keyframes } from "styled-components";

export const HeaderContainer = styled.header`
  height: 100vh;
  padding: 1rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 60%;
  min-height: 1000px;
  @media (min-width: 800px) and (max-width: 1200px) {
    height: 100vh;
    width: 80%;
    min-height: 800px;
  }
  @media screen and (max-width: 800px) {
    height: 100vh;
    width: 85%;
    min-height: 800px;
  }
`;

export const NameText = styled.h1`
  font-size: 6rem;
  letter-spacing: 1px;
  @media (min-width: 800px) and (max-width: 1200px) {
    font-size: 4rem;
  }
  @media screen and (max-width: 800px) {
    font-size: 2rem;
  }
`;

export const JobTitle = styled.h2`
  font-size: 4rem;
  letter-spacing: 2px;
  color: var(--gray--text);
  @media (min-width: 800px) and (max-width: 1200px) {
    font-size: 2.5rem;
    margin-top: 1rem;
  }
  @media screen and (max-width: 800px) {
    font-size: var(--font-size-xl);
    margin-top: 1rem;
  }
`;

export const Description = styled.p`
  font-size: var(--font-size-xl);
  color: var(--gray--text);
  @media (min-width: 800px) and (max-width: 1200px) {
    font-size: var(--font-size-2xl);
    margin-top: 4rem;
  }
  @media screen and (max-width: 800px) {
    font-size: var(--font-size-lg);
    margin-top: 3rem;
  }
`;

export const HeaderButtons = styled.div`
  margin-top: 5rem;
  display: flex;
  gap: 2rem;
  @media screen and (max-width: 800px) {
    margin-top: 6rem;
  }
`;

const pulseAnimation = keyframes`
	0% {
		transform: scale(0.90);
	}

	70% {
		transform: scale(1.1);
	}

	100% {
		transform: scale(0.90);
	}
`;

export const DownloadCvButton = styled.a`
  &:hover {
    transform: scale(1);
    animation: ${pulseAnimation} 1s linear infinite;
  }
  @media screen and (max-width: 800px) {
    font-size: 11px;
  }
`;

export const LinkToContact = styled.a`
  &:hover {
    transform: scale(1);
    animation: ${pulseAnimation} 1s linear infinite;
  }
  @media screen and (max-width: 800px) {
    font-size: 11px;
  }
`;

export const Photo = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin: auto;
`;

export const Logo = styled.img`
  padding-top: 3rem;
  width: 80px;
  height: 55px;
  object-fit: contain;
  padding-bottom: 10rem;
  @media screen and (max-width: 800px) {
    padding-bottom: 10rem;
  }
`;
