import styled from "styled-components";

export const ExperienceContainer = styled.section`
  height: 100vh;
  padding: 1rem 1rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 60%;
  min-height: 1200px;
  @media (min-width: 800px) and (max-width: 1200px) {
    height: fit-content;
    width: 90%;
  }
  @media screen and (max-width: 800px) {
    height: fit-content;
    width: 90%;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 5px;
`;

export const Title = styled.a`
  all: unset;
  color: white;
  font-size: var(--font-size-xl);
  text-align: left;
  font-weight: 600;
  padding-bottom: 20px;
  cursor: pointer;
`;
export const JobTitle = styled.p`
  color: var(--gray--text);
  font-size: var(--font-size-lg);
  text-align: left;
  font-weight: 400;
  padding-bottom: 10px;
`;

export const Date = styled.p`
  color: var(--gray--text);
  font-size: var(--font-size-m);
  text-align: left;
`;

export const DescriptionListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  padding-top: 3rem;
`;

export const DescriptionItem = styled.li`
  position: relative;
  width: 90%;
  padding-left: 30px;
  padding-right: 5%;
  margin-bottom: 10px;
  color: var(--gray--text);
  font-size: var(--font-size-m);
  list-style-type: none;
  ::before {
    content: ">";
    font-size: var(--font-size-xl);
    position: absolute;
    left: 0px;
    color: var(--color--secondary);
  }
`;
