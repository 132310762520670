import React from "react";
import { Autoplay, EffectCoverflow } from "swiper";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";

import useIsMobile from "../../hooks/useIsMobile";
import SwipeAnimation from "../swipeAnimation";
import "./styles.css";

const ScreensCarousel = ({ cards, isFocused, desktopFormat }) => {
  const isMobile = useIsMobile();

  const getSwiperDimensions = () => {
    let dimensions = {
      width: "400px",
      height: "620px",
    };
    if (isMobile) {
      dimensions.height = "200px";
      dimensions.width = "310px";
      if (desktopFormat) {
      } else {
        dimensions.height = "310px";
        dimensions.width = "200px";
      }
    } else if (desktopFormat) {
      dimensions.height = "400px";
      dimensions.width = "620px";
    }
    return dimensions;
  };

  const getImageWidth = () => {
    let dimensions = {
      width: "300px",
    };
    if (isMobile) {
      if (desktopFormat) {
        dimensions.width = "300px";
      } else {
        dimensions.width = "150px";
      }
    } else if (desktopFormat) {
      dimensions.width = "620px";
    }
    return dimensions;
  };
  return (
    <SwipeAnimation direction={"up"} isFocused={isFocused}>
      <Swiper
        style={getSwiperDimensions()}
        direction='vertical'
        effect='coverflow'
        speed={1500}
        autoplay={{
          delay: 3000,
          disableOnInteraction: true,
        }}
        coverflowEffect={{ slideShadows: false }}
        modules={[Autoplay, EffectCoverflow]}
      >
        {cards.map((card) => (
          <SwiperSlide key={card.alt}>
            <img style={getImageWidth()} src={card.image} alt={card.alt} />
          </SwiperSlide>
        ))}
      </Swiper>
    </SwipeAnimation>
  );
};

export default ScreensCarousel;
