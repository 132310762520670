import styled from "styled-components";

export const TopTag = styled.h2`
  text-align: left;
  padding-top: 5rem;
  font-size: var(--font-size-xl);
  font-family: var(--font--shadow);
  width: 100%;
`;
export const BottomTag = styled.h2`
  text-align: left;
  padding-bottom: 5rem;
  font-size: var(--font-size-xl);
  font-family: var(--font--shadow);
  width: 100%;
`;
