import styled, { css, keyframes } from "styled-components";

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
`;

export const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 500px;
  width: 100%;
  justify-content: flex-start;
  @media (min-width: 800px) and (max-width: 1200px) {
    flex-direction: column;
    justify-content: unset;
    align-items: flex-start;
  }
  @media screen and (max-width: 800px) {
    flex-direction: column;
    justify-content: unset;
    align-items: flex-start;
  }
`;
export const TabsSelector = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 400px;
  justify-content: flex-start;
  @media (min-width: 800px) and (max-width: 1200px) {
    overflow-x: scroll;
    flex-direction: row;
    width: 90%;
    min-height: 50px;
    ::-webkit-scrollbar {
      display: none;
    }
    gap: 1.5rem;
  }
  @media screen and (max-width: 800px) {
    overflow-x: scroll;
    flex-direction: row;
    max-width: 400px;
    min-height: 50px;
    ::-webkit-scrollbar {
      display: none;
    }
    gap: 1rem;
  }
`;
export const TabButton = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  @media (min-width: 800px) and (max-width: 1200px) {
    min-width: fit-content;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @media screen and (max-width: 800px) {
    min-width: fit-content;
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;
export const TabContent = styled.div`
  flex: 1;
  padding: 20px;
  height: 100%;
`;

export const TabTitle = styled.p`
  font-weight: 300;
  font-size: var(--font-size-m);
  color: ${(props) => (props.active ? "#fff" : "var(--gray--text)")};
`;

export const Circle = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid var(--color--secondary);
  margin-right: 20px;
  ${(props) =>
    props.active &&
    css`
      animation: ${pulse} 1s ease-in-out infinite;
    `}
`;
