import React from "react";
import { CgMail } from "react-icons/cg";
import { TbBrandLinkedin } from "react-icons/tb";
import { TbBrandGithub } from "react-icons/tb";

import useIsMobile from "../../hooks/useIsMobile";
import * as Styled from "./styles";

const Socials = () => {
  const isMobile = useIsMobile();
  return (
    <>
      {isMobile ? (
        <Styled.BottomSocials>
          <Styled.SocialLink
            target='_blank'
            href='https://www.linkedin.com/in/ignacio-contreres-kirkby-a8951b140/'
          >
            <TbBrandLinkedin size={30} />
          </Styled.SocialLink>
          <Styled.SocialLink href='mailto:contreresignacio@gmail.com'>
            <CgMail size={30} />
          </Styled.SocialLink>
          <Styled.SocialLink
            target='_blank'
            href='https://github.com/nachocontreres/'
          >
            <TbBrandGithub size={30} />
          </Styled.SocialLink>
        </Styled.BottomSocials>
      ) : (
        <Styled.SocialsContainer>
          <Styled.SocialsTop>
            <Styled.Bar />
            <Styled.EmailText>contreresignacio@gmail.com</Styled.EmailText>
          </Styled.SocialsTop>
          <Styled.SocialsBelow>
            <Styled.SocialLink
              target='_blank'
              href='https://www.linkedin.com/in/ignacio-contreres-kirkby-a8951b140/'
            >
              <TbBrandLinkedin size={30} />
            </Styled.SocialLink>
            <Styled.SocialLink
              target='_blank'
              href='https://github.com/nachocontreres/'
            >
              <TbBrandGithub size={30} />
            </Styled.SocialLink>
            <Styled.Bar />
          </Styled.SocialsBelow>
        </Styled.SocialsContainer>
      )}
    </>
  );
};

export default Socials;
