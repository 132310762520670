export const projectsData = [
    {
    title: "Loop",
    companyUrl:
      "https://www.loopmein.me/",
    projectKey: "loop",
    desktopFormat: false,
    descriptions: [
      "I have been working on Loop since its initial launch to production, where I contributed to improving and creating core features. Loop enables you to set up your friends with other singles, feature them, create groups for events, and more.",
      "Key features I contributed: Login, Home Feed Cards, Setups and Groups",
      "Tech stack used: React Native, Next js, GraphQl, Nest Js, Typescript, AWS",
    ],
  },

  {
    title: "AAOS Membership",
    companyUrl:
      "https://www.aaos.org/membership/aaos-member-resources/mobile-apps/membership-app/",
    projectKey: "aaos",
    desktopFormat: false,
    descriptions: [
      "I had the opportunity to contribute to the development of an app for the American Academy of Orthopaedic Surgeons. This app serves as a comprehensive platform for musculoskeletal specialists to access education and practice management services.",
      "Key features I contributed: Member Directory, Chat, and Online Learning modules.",
      "Tech stack used: React Native, Typescript, Lodash, Axios, Redux Toolkit, Twilio, Gifted Chat, i18next",
    ],
  },
  {
    title: "TrueBlue - Centerline Drivers Qualifications",
    companyUrl: "https://www.centerlinedrivers.com/",
    projectKey: "cdq",
    desktopFormat: true,
    descriptions: [
      "The Centerline Portal is a Service Team User Interface for completing administrative tasks that link to external third party software.  It is also a Client facing interface for requesting, approving, and rejecting business offers in desktop and mobile platforms.",
      "Key features I contributed: Driver Qualifications, Expenses, Quote Engine, Admin Portal, SMS Multi Factor Authentication.",
      "Tech stack used: React js, React Native, Redux Toolkit, Amplify, GraphQL, DynamoDB, AWS, Lodash",
    ],
  },
  {
    title: "Bellxcel",
    companyUrl: "https://www.bellxcel.org/",
    projectKey: "bellxcel",
    desktopFormat: false,
    descriptions: [
      "The BX app is a powerful and easy-to-use communication app for youth programs that makes it easier than ever for teachers, staff, and administrators to build connections with families and guardians.",
      "Key features I contributed: Posts Feed, Chat, Translation, Login",
      "Tech stack used: React Native, Axios, Redux Toolkit, Twilio, Gifted Chat, i18next",
    ],
  },
  {
    title: "CDG - MBS Field Tech",
    companyUrl: "https://www.cdg.ws/index.php",
    projectKey: "cdg",
    desktopFormat: false,
    descriptions: [
      "MBS Field Tech mobile app for Android and iOS provides tools for the employees to manage their work from anywhere. From updating customer premise equipment and assigning available equipment for service orders to working tasks and trouble tickets, MBS Field Tech can help with it all.",
      "Key features I contributed: Map, Jobs,Equipment, Tasks, Barcode scanner",
      "React Native, Redux Toolkit, React Native Maps, Axios, Moment js, lodash, i18next, Storybook",
    ],
  },
  {
    title: "PureB2B",
    projectKey: "pureb2b",
    desktopFormat: true,

    descriptions: [
      "PureB2b is an app designed specifically for professionals in the marketing industry. It provides an all-in-one platform for launching campaigns, targeting the right audience, and developing effective marketing strategies. With PureB2b, users can access a content type information to help them make informed decisions about their marketing efforts. ",
      "Key features I contributed: Dashboard, Bookmarks, Account, Billing Information, Top Trends, Custom Charts",
      "React js, Redux, React Native, Typescript, Styled Components, Nodejs, DynamoDB, CloudWatch, Lambda, Serverless",
    ],
  },
  {
    title: "Klarity",
    projectKey: "klarity",
    desktopFormat: true,
    descriptions: [
      "Klarity is a revolutionary app that provides salespeople with the tools they need to succeed. With its advanced filtering system, Klarity allows users to easily find relevant information about companies based on criteria such as industry, location, revenue, and more. In addition to providing comprehensive company information, Klarity also offers contact information for key employees, making it easy for salespeople to connect with potential clients.",
      "Key features I contributed: Dashboard, List View, Card View, Filters, Bookmarks, Login, Sign up",
      "React js, Redux, Typescript, Styled Components, Nodejs, DynamoDB, CloudWatch, Lambda, Serverless",
    ],
  },
  {
    title: "Recognite - Portal",
    companyUrl: "https://www.recognite.us/",
    projectKey: "recognite",
    desktopFormat: true,
    descriptions: [
      "Recognite is an AI-based brand control solution that includes instant facial recognition attendance management. This solution provides businesses with an effective tool for managing employee attendance. By implementing this solution, businesses can enhance accessibility and improve their ability to manage staff attendance. ",
      "I've designed and developed the Portal for Recognite, also worked on most of the features of the mobile app.",
      "React js, React Native, Redux, Typescript, Styled Components, Nodejs, MySql.",
    ],
  },
];
