import styled from "styled-components";

export const AboutContainer = styled.section`
  height: 100vh;
  padding: 1rem 1rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
  width: 60%;
  min-height: 1200px;
  @media (min-width: 800px) and (max-width: 1200px) {
    width: 90%;
    height: 1400px;
  }
  @media screen and (max-width: 800px) {
    width: 90%;
    height: 1500px;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const TextInfoContainer = styled.div`
  height: 100%;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media (min-width: 800px) and (max-width: 1200px) {
    width: 90%;
  }
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const ImageContainer = styled.div`
  height: 100%;
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (min-width: 800px) and (max-width: 1200px) {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  @media screen and (max-width: 800px) {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
`;
export const Description = styled.p`
  font-size: var(--font-size-lg);
  color: var(--gray--text);
  width: 100%;
  margin-bottom: 2rem;
  padding-left: 6rem;
  @media (min-width: 800px) and (max-width: 1200px) {
    font-size: var(--font-size-m);
    padding-top: 1rem;
    margin-bottom: 1rem;
    padding-left: 1.5rem;
  }
  @media screen and (max-width: 800px) {
    font-size: var(--font-size-s);
    padding-top: 1rem;
    margin-bottom: 1rem;
    padding-left: 1.5rem;
  }
`;

export const Image = styled.img`
  background-repeat: no-repeat;
  height: 20rem;
  width: 14rem;
  filter: grayscale(100%);
  transition: var(--transition);
  &:hover {
    filter: none;
    transform: scale(1.1);
  }
  padding: 10px;
  box-shadow: -41px -41px 0 -40px white, 41px 41px 0 -40px white;
  @media (min-width: 800px) and (max-width: 1200px) {
    filter: none;
  }
  @media screen and (max-width: 800px) {
    filter: none;
  }
`;

export const SkillsContainer = styled.div`
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 6rem;
  @media (min-width: 800px) and (max-width: 1200px) {
    padding-left: 1.5rem;
  }
  @media screen and (max-width: 800px) {
    padding-left: 1.5rem;
  }
`;

export const Skill = styled.div`
  width: 200px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  &:hover {
    transform: scale(1.1);
    z-index: 1000;
  }

  @media screen and (max-width: 800px) {
    max-width: 42%;
    margin: 0 10px;
    font-size: 11px;
  }
`;
export const SkillIcon = styled.img`
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  color: var(--color--secondary);
`;
