import styled from "styled-components";

export const ProjectsContainer = styled.section`
  height: 100vh;
  padding: 1rem 1rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 60%;
  min-height: 1200px;
  @media (min-width: 800px) and (max-width: 1200px) {
    height: fit-content;
    width: 90%;
  }
  @media screen and (max-width: 800px) {
    height: fit-content;
    width: 90%;
  }
`;
export const Card = styled.div`
  height: 900px;
  width: 100%;
  background-color: var(--color--secondary--strongest);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px;
  margin: 0px 20px;
  @media (min-width: 800px) and (max-width: 1200px) {
    height: 800px;
  }
  @media screen and (max-width: 800px) {
    height: 1150px;
  }
`;
export const CardTitle = styled.a`
  all: unset;
  cursor: pointer;
  font-size: var(--font-size-3xl);
  color: var(--gray--text);
  font-weight: 300;
  @media (min-width: 800px) and (max-width: 1200px) {
    font-size: var(--font-size-3xl);
  }
  @media screen and (max-width: 800px) {
    font-size: var(--font-size-xl);
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  flex-direction: ${(props) => (props.desktopFormat ? "column" : "row")};
  @media (min-width: 800px) and (max-width: 1200px) {
    flex-direction: column;
  }
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

export const CardsContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const CardDescription = styled.div`
  flex: 1;
  text-align: left;
  word-break: break-all;
  padding: 2rem 2rem;
`;

export const TextDescription = styled.p`
  text-align: left;
  word-break: break-word;
  //change font size to change to lg when is mobile format
  font-size: var(--font-size-m);
  color: var(--gray--text);
  font-weight: 300;
  margin-bottom: 1rem;
  @media (min-width: 800px) and (max-width: 1200px) {
    font-size: var(--font-size-m);
  }
  @media screen and (max-width: 800px) {
    font-size: var(--font-size-s);
  }
`;
