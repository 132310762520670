import Modal from "react-modal";

import * as Styled from "./styles";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

export const SuccessModal = ({ successModalOpen, setSuccessModalOpen }) => (
  <Modal isOpen={successModalOpen} style={customStyles}>
    <Styled.ModalTitle>Success!</Styled.ModalTitle>
    <Styled.ModalMessage>
      Your email has been sent successfully.
    </Styled.ModalMessage>
    <Styled.CloseModal
      className='btn'
      onClick={() => setSuccessModalOpen(false)}
    >
      Close
    </Styled.CloseModal>
  </Modal>
);

export const ErrorModal = ({ errorModalOpen, setErrorModalOpen }) => (
  <Modal isOpen={errorModalOpen} style={customStyles}>
    <Styled.ModalTitle>Oops</Styled.ModalTitle>
    <Styled.ModalMessage>
      Sorry, we could not send your message. Please try again later.
    </Styled.ModalMessage>
    <Styled.CloseModal className='btn' onClick={() => setErrorModalOpen(false)}>
      Close
    </Styled.CloseModal>
  </Modal>
);
