import React from "react";

import CustomCursor from "./components/customCursor";
import Nav from "./components/nav";
import Socials from "./components/socials";
import About from "./features/about";
import Contact from "./features/contact";
import Experience from "./features/experience";
import Header from "./features/header";
import Projects from "./features/projects";

const App = () => {
  return (
    <>
      <CustomCursor />
      <Nav />
      <Header />
      <About />
      <Experience />
      <Projects />
      <Contact />
      <Socials />
    </>
  );
};

export default App;
