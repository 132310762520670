import React from "react";

import BallsTabs from "../../components/balls-tabs";
import useIsMobile from "../../hooks/useIsMobile";
import { BottomTag, TopTag } from "../commonStyles";
import { experienceData } from "./experienceData";
import * as Styled from "./styles";

const Experience = () => {
  const isMobile = useIsMobile();
  return (
    <Styled.ExperienceContainer id='experience' className='container'>
      <TopTag>{"<experience>"}</TopTag>
      <BallsTabs
        tabs={experienceData}
        containerStyles={{
          height: isMobile ? "fit-content" : "800px",
          paddingLeft: isMobile ? 0 : "10rem",
          paddingTop: isMobile ? "2rem" : 0,
        }}
      />
      <BottomTag>{"</experience>"}</BottomTag>
    </Styled.ExperienceContainer>
  );
};

export default Experience;
