import { useState } from "react";

import {
  Circle,
  TabButton,
  TabContainer,
  TabContent,
  TabTitle,
  TabsSelector,
} from "./styles";

const BallsTabs = ({ tabs, containerStyles = {} }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].id);

  return (
    <TabContainer style={containerStyles}>
      <TabsSelector>
        {tabs.map((tab) => (
          <TabButton key={tab.id} onClick={() => setActiveTab(tab.id)}>
            <Circle active={tab.id === activeTab} />
            <TabTitle active={tab.id === activeTab}>{tab.title}</TabTitle>
          </TabButton>
        ))}
      </TabsSelector>

      <TabContent>
        {tabs.find((tab) => tab.id === activeTab).content}
      </TabContent>
    </TabContainer>
  );
};

export default BallsTabs;
