import { AiOutlineHome } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";
import { CgWorkAlt } from "react-icons/cg";
import { HiOutlineEnvelope } from "react-icons/hi2";
import { SiOpenbadges } from "react-icons/si";

export const navData = [
  { option: "#", icon: <AiOutlineHome size={30} /> },
  { option: "#about", icon: <AiOutlineUser size={30} /> },
  { option: "#experience", icon: <CgWorkAlt size={30} /> },
  { option: "#projects", icon: <SiOpenbadges size={30} /> },
  { option: "#contact", icon: <HiOutlineEnvelope size={30} /> },
];

export const navMobileData = [
  { option: "#", icon: <AiOutlineHome size={20} /> },
  { option: "#about", icon: <AiOutlineUser size={20} /> },
  { option: "#experience", icon: <CgWorkAlt size={20} /> },
  { option: "#projects", icon: <SiOpenbadges size={20} /> },
  { option: "#contact", icon: <HiOutlineEnvelope size={20} /> },
];
