import React, { useEffect, useState } from "react";

import Hand from "../../assets/hand.png";
import useIsMobile from "../../hooks/useIsMobile";
import "./swipeAnimation.css";

const SwipeAnimation = ({ isFocused, direction = "left", children }) => {
  const isMobile = useIsMobile();
  const [showSwipeAnimation, setShowSwipeAnimation] = useState(
    isMobile ? false : true
  );

  useEffect(() => {
    if (isFocused) {
      const timeoutId = setTimeout(() => {
        setShowSwipeAnimation(false);
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [isFocused]);

  const getAnimationStyles = () => {
    switch (direction) {
      case "up":
        return {
          hand: {
            animation: "swipe-hand-up 1.5s infinite",
            filter: "invert(1)",
          },
          dot: {
            animation: "swipe-dot-up 1.5s infinite",
          },
          container: {
            top: "-150px",
            left: "150px",
          },
        };
      default:
        return {};
    }
  };

  const {
    hand: handStyles,
    dot: dotStyles,
    container: containerStyles,
  } = getAnimationStyles();

  return (
    <>
      {showSwipeAnimation && (
        <div className='swipe-animation' style={containerStyles}>
          <div className='path' style={dotStyles}></div>
          <img className='hand-icon' src={Hand} alt='hand' style={handStyles} />
        </div>
      )}
      {children}
    </>
  );
};

export default SwipeAnimation;
