import React from "react";

import Logo from "../../assets/logo.png";
import HeaderButtons from "./HeaderButtons";
import * as Styled from "./styles";

const Header = () => {
  return (
    <Styled.HeaderContainer className='container'>
      <Styled.Logo src={Logo} />
      <Styled.NameText>Ignacio Contreres</Styled.NameText>
      <Styled.JobTitle>Frontend Developer</Styled.JobTitle>
      <Styled.Description>
        I specialize in crafting engaging and intuitive user experiences for
        websites and mobile applications.
      </Styled.Description>
      <HeaderButtons />
    </Styled.HeaderContainer>
  );
};

export default Header;
