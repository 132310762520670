import React, { useState } from "react";

import useIsMobile from "../../hooks/useIsMobile";
import { navData, navMobileData } from "./navData";
import * as Styled from "./styles";

const Nav = () => {
  const [activeOption, setActiveOption] = useState("#");
  const isMobile = useIsMobile();

  return (
    <Styled.Nav>
      {(!isMobile ? navData : navMobileData).map(({ option, icon }) => (
        <Styled.HeaderItem
          key={option}
          onClick={() => setActiveOption(option)}
          href={option}
          isActive={activeOption === option}
        >
          {icon}
        </Styled.HeaderItem>
      ))}
    </Styled.Nav>
  );
};

export default Nav;
