import React from "react";
import * as Styled from "./styles";

const ExperienceContent = ({
  company,
  companyUrl,
  jobTitle,
  date,
  descriptions,
}) => {
  return (
    <Styled.ContentContainer>
      <Styled.Title href={companyUrl} target="_blank">
        {company}
      </Styled.Title>
      <Styled.JobTitle>{jobTitle}</Styled.JobTitle>
      <Styled.Date>{date}</Styled.Date>
      <Styled.DescriptionListContainer>
        {descriptions.map((item, index) => {
          return (
            <Styled.DescriptionItem key={index}>{item}</Styled.DescriptionItem>
          );
        })}
      </Styled.DescriptionListContainer>
    </Styled.ContentContainer>
  );
};

export default ExperienceContent;
