import styled from "styled-components";

export const Nav = styled.nav`
  width: max-content;
  display: flex;
  padding: 0.8rem 3rem;
  z-index: 1000;
  position: fixed;
  left: 50%;
  bottom: 1rem;
  gap: 3rem;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  @media (min-width: 800px) and (max-width: 1200px) {
    width: 55%;
    padding: 0.8rem 0.9rem;
    gap: 2.5rem;
  }
  @media screen and (max-width: 800px) {
    width: 75%;
    padding: 0.7rem 0.9rem;
    gap: 1.5rem;
  }
`;

export const HeaderItem = styled.a`
  width: fit-content;
  min-width: auto;
  color: ${(props) => (props.isActive ? "var(--color--secondary)" : "")};
  gap: 2rem;
`;
