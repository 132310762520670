import styled from "styled-components";

export const SocialsContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  align-items: center;
  height: 100%;

  @media (max-width: 1200px) {
    display: none;
  }
`;

export const SocialsBelow = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100px;
  max-height: 25vh;
  flex: 1;
  width: 30px;
  align-items: center;
  gap: 1.5rem;
  justify-content: flex-end;
`;

export const SocialsTop = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 50vh;
  min-height: 300px;
  flex: 1;
  width: 30px;
  align-items: center;
  gap: 2rem;
  justify-content: flex-end;
`;

export const EmailText = styled.p`
  color: var(--gray--text);
  font-size: var(--font-size-m);
  writing-mode: vertical-rl;
  transform: rotate(180deg);
`;

export const SocialLink = styled.a`
  color: var(--gray--text);
  display: flex;
  justify-content: center;
  &:hover {
    transform: scale(1.5);
  }
`;
export const Bar = styled.div`
  width: 1px;
  flex: 1;
  background-color: var(--gray--text);
`;

export const BottomSocials = styled.section`
  height: 50px;
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: auto;
  margin-bottom: 8rem;

  @media (min-width: 1200px) {
    display: none;
  }
`;
