import styled from "styled-components";

export const ContactContainer = styled.section`
  height: 100vh;
  padding: 1rem 1rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 60%;
  min-height: 1200px;
  @media (min-width: 800px) and (max-width: 1200px) {
    height: 100vh;
    width: 90%;
    min-height: fit-content;
  }
  @media screen and (max-width: 800px) {
    height: 100vh;
    width: 90%;
    min-height: fit-content;
  }
`;

export const Title = styled.h2`
  font-size: 3rem;
  color: var(--gray--text);
  @media screen and (max-width: 800px) {
    font-size: var(--font-size-xl);
  }
`;

export const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  width: 75%;
  @media screen and (max-width: 800px) {
    width: 90%;
    gap: 2rem;
  }
`;

const InputField = styled.input`
  all: unset;

  //autocomplete was changing bg color to white
  input:-webkit-autofill,
  &,
  &:hover,
  &:focus,
  &:active {
    transition-delay: 9999s;
    transition-property: background-color, color;
  }
  padding-left: 15px;
  width: calc(100% - 15px);
  height: 50px;
  border: 2px solid var(--color--secondary--strong);
  color: white;
  border-radius: 5px;
  font-size: var(--font-size-m);
  font-weight: 300;
  ::placeholder {
    color: white;
  }
  @media screen and (max-width: 800px) {
    font-size: var(--font-size-s);
  }
`;

export const ContactInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

export const Email = styled(InputField)`
  width: 50%;
  @media screen and (max-width: 800px) {
    width: calc(100% - 15px);
  }
`;

export const Name = styled(InputField)`
  width: 50%;
  @media screen and (max-width: 800px) {
    width: calc(100% - 15px);
  }
`;

export const Subject = styled(InputField)``;

export const Message = styled.textarea`
  all: unset;
  width: 100%;
  height: 100px;
  border: 2px solid var(--color--secondary--strong);
  color: white;
  padding: 5px 20px;
  border-radius: 5px;
  font-size: var(--font-size-m);
  box-sizing: border-box;
  ::placeholder {
    color: white;
  }
`;

export const SendButton = styled.button`
  width: 100px;
  margin-top: 2rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: 2px;
`;

export const ModalTitle = styled.h1`
  font-family: "Poppins", sans-serif;
  letter-spacing: 2px;
  font-size: var(--font-size-2xl);
  color: var(--color--secondary--strong);
`;

export const ModalMessage = styled.p`
  font-family: "Poppins", sans-serif;
  letter-spacing: 2px;
  font-size: var(--font-size-m);
  color: var(--gray--text);
`;

export const CloseModal = styled.button`
  width: 80px;
  margin-top: 2rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: 2px;
`;
