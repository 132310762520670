import AAOSDashboard from "../assets/aaos/aaos_dashboard_phone.png";
import AAOSMessage from "../assets/aaos/aaos_message_phone.png";
import AAOSProfile from "../assets/aaos/aaos_profile_phone.png";
import AAOSSettings from "../assets/aaos/aaos_settings_phone.png";
import AAOSSplash from "../assets/aaos/aaos_splash_phone.png";
import AAOSWelcome from "../assets/aaos/aaos_welcome_phone.png";
import BellxcelChat from "../assets/bellxcel/chat_bxl_phone.png";
import BellxcelChatList from "../assets/bellxcel/chat_list_bxl_phone.png";
import BelxcellFeed from "../assets/bellxcel/feed_bxl_phone.png";
import BellxcelSettings from "../assets/bellxcel/settings_bxl_phone.png";
import BellxcelSplash from "../assets/bellxcel/splash_bxl_phone.png";
import BellxcelWelcome from "../assets/bellxcel/welcome_bxl_phone.png";
import CDGAddEquipment from "../assets/cdg/cdg_addequipment_phone.png";
import CDGFleetView from "../assets/cdg/cdg_fleet_view_phone.png";
import CDGInventory from "../assets/cdg/cdg_inventory_phone.png";
import CDGMyJobs from "../assets/cdg/cdg_myjobs_phone.png";
import CDGSplash from "../assets/cdg/cdg_splash_phone.png";
import Klarity1 from "../assets/klarity/klarity_1_desktop.png";
import Klarity2 from "../assets/klarity/klarity_2_desktop.png";
import Pureb2b1 from "../assets/pureb2b/pureb2b_1_desktop.png";
import Pureb2b2 from "../assets/pureb2b/pureb2b_2_desktop.png";
import Pureb2b3 from "../assets/pureb2b/pureb2b_3_desktop.png";
import Reco1 from "../assets/recognite/rp_desktop1.png";
import Reco2 from "../assets/recognite/rp_desktop2.png";
import Reco3 from "../assets/recognite/rp_desktop3.png";
import Reco4 from "../assets/recognite/rp_desktop4.png";
import Reco5 from "../assets/recognite/rp_desktop5.png";
import Tb1 from "../assets/trueblue/tb1_desktop.png";
import LoopLogin from "../assets/loop/login.png";
import LoopFeedCard from "../assets/loop/feed_card.png";
import LoopYourLoop from "../assets/loop/your_loop.png";
import LoopSetups from "../assets/loop/setups.png";
import LoopGroups from "../assets/loop/groups.png";
import LoopGroup from "../assets/loop/group.png";
import LoopUserProfile from "../assets/loop/user_profile.png";
import LoopMyProfile from "../assets/loop/my_profile.png";
import LoopFilters from "../assets/loop/filters.png";

export const cards = {
  loop: [
    { image: LoopLogin, alt: "loop_login" },
    { image: LoopFeedCard, alt: "loop_feed_card" },
    { image: LoopYourLoop, alt: "loop_your_loop" },
    { image: LoopSetups, alt: "loop_setups" },
    { image: LoopGroups, alt: "loop_groups" },
    { image: LoopGroup, alt: "loop_group" },
    { image: LoopUserProfile, alt: "loop_user_profile" },
    { image: LoopMyProfile, alt: "loop_my_profile" },
    { image: LoopFilters, alt: "loop_filters" },

  ],
  bellxcel: [
    { image: BellxcelSplash, alt: "bellxcel_splash" },
    { image: BellxcelWelcome, alt: "bellxcel_welcome" },
    { image: BelxcellFeed, alt: "bellxcel_feed" },
    { image: BellxcelSettings, alt: "bellxcel_settings" },
    { image: BellxcelChatList, alt: "bellxcel_chat_list" },
    { image: BellxcelChat, alt: "bellxcel_chat" },
  ],
  cdq: [{ image: Tb1, alt: "Tb1" }],
  aaos: [
    { image: AAOSSplash, alt: "aaos_splash" },
    { image: AAOSWelcome, alt: "aaos_welcome" },
    { image: AAOSDashboard, alt: "aaos_dashboard" },
    { image: AAOSMessage, alt: "aaos_message" },
    { image: AAOSProfile, alt: "aaos_profile" },
    { image: AAOSSettings, alt: "aaos_settings" },
  ],
  cdg: [
    { image: CDGSplash, alt: "cdg_splash" },
    { image: CDGFleetView, alt: "cdg_fleet_view" },
    { image: CDGMyJobs, alt: "cdg_my_jobs" },
    { image: CDGInventory, alt: "cdg_inventory" },
    { image: CDGAddEquipment, alt: "cdg_add_equipment" },
  ],
  klarity: [
    { image: Klarity1, alt: "klarity1" },
    { image: Klarity2, alt: "klarity2" },
  ],
  pureb2b: [
    { image: Pureb2b1, alt: "pureb2b1" },
    { image: Pureb2b2, alt: "pureb2b2" },
    { image: Pureb2b3, alt: "pureb2b3" },
  ],
  recognite: [
    { image: Reco1, alt: "reco1" },
    { image: Reco2, alt: "reco2" },
    { image: Reco3, alt: "reco3" },
    { image: Reco4, alt: "reco4" },
    { image: Reco5, alt: "reco5" },
  ],
};
