import React, { useState } from "react";
import { EffectCards, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";

import ScreensCarousel from "../../components/screensCarousel";
import SwipeAnimation from "../../components/swipeAnimation";
import useIsMobile from "../../hooks/useIsMobile";
import { cards } from "../appsImages";
import { BottomTag, TopTag } from "../commonStyles";
import { projectsData } from "./projetsData";
import * as Styled from "./styles";

const Projects = () => {
  const [isFocused, setIsFocused] = useState(false);
  const isMobile = useIsMobile();

  const handleFocus = () => {
    setIsFocused(true);
  };

  return (
    <Styled.ProjectsContainer
      id='projects'
      className='container'
      onMouseMove={handleFocus}
    >
      <TopTag>{"<projects>"}</TopTag>
      <Styled.CardsContainer>
        <SwipeAnimation isFocused={isFocused} direction={"left"}>
          <Swiper
            style={{
              width: "100%",
              height: isMobile ? "1200px" : "100%",
            }}
            direction='horizontal'
            effect='fade'
            speed={2000}
            cardsEffect={{
              slideShadows: false,
              rotate: false,
            }}
            navigation
            pagination
            modules={[Navigation, EffectCards, Pagination]}
          >
            {projectsData.map((card) => (
              <SwiperSlide style={{ transition: "2s all" }} key={card.title}>
                <Styled.Card>
                  <Styled.CardTitle href={card.companyUrl} target='_blank'>
                    {card.title}
                  </Styled.CardTitle>
                  <Styled.CardContent desktopFormat={card.desktopFormat}>
                    <ScreensCarousel
                      isFocused={isFocused}
                      key={card.title}
                      desktopFormat={card.desktopFormat}
                      cards={cards[card.projectKey]}
                    />
                    <Styled.CardDescription>
                      {card.descriptions?.map((description) => (
                        <Styled.TextDescription key={description}>
                          {description}
                        </Styled.TextDescription>
                      ))}
                    </Styled.CardDescription>
                  </Styled.CardContent>
                </Styled.Card>
              </SwiperSlide>
            ))}
          </Swiper>
        </SwipeAnimation>
      </Styled.CardsContainer>

      <BottomTag>{"</projects>"}</BottomTag>
    </Styled.ProjectsContainer>
  );
};

export default Projects;
