import React from "react";

import useCursorPosition from "../../hooks/useCursorPosition";
import "./styles.css";

const CustomCursor = () => {
  const position = useCursorPosition();

  return (
    <>
      <div
        className='neon-spinner'
        style={{ left: `${position.x - 50}px`, top: `${position.y - 50}px` }}
      >
        <div className='circle circle-1'></div>
        <div className='circle circle-2'></div>
      </div>
    </>
  );
};

export default CustomCursor;
