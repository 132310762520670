import React from "react";

import Curriculum from "../../assets/CV - Ignacio Contreres.pdf";
import * as Styled from "./styles";

const HeaderButtons = () => {
  return (
    <Styled.HeaderButtons>
      <Styled.DownloadCvButton className='btn' download href={Curriculum}>
        Download CV
      </Styled.DownloadCvButton>
      <Styled.LinkToContact className='btn-secondary' href='#contact'>
        Let's Talk
      </Styled.LinkToContact>
    </Styled.HeaderButtons>
  );
};

export default HeaderButtons;
