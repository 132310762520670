import { FaReact } from "react-icons/fa";
import { FaNodeJs } from "react-icons/fa";
import { GrGraphQl } from "react-icons/gr";
import { SiJavascript } from "react-icons/si";
import { SiTypescript } from "react-icons/si";
import { SiRedux } from "react-icons/si";
import { SiAmazonaws } from "react-icons/si";
import { TbBrandReactNative } from "react-icons/tb";

import StyledComponentsIcon from "../../assets/styled-components.png";

const iconStyle = {
  width: "20px",
  height: "20px",
  backgroundRepeat: "no-repeat",
  color: "#4F1A73",
};
export const skillsData = [
  { name: "React js", icon: <FaReact style={iconStyle} /> },
  { name: "React Native", icon: <TbBrandReactNative style={iconStyle} /> },
  { name: "JavaScript", icon: <SiJavascript style={iconStyle} /> },
  { name: "TypeScript", icon: <SiTypescript style={iconStyle} /> },
  { name: "GraphQL", icon: <GrGraphQl style={iconStyle} /> },
  { name: "Redux", icon: <SiRedux style={iconStyle} /> },
  {
    name: "Styled Components",
    icon: (
      <img
        src={StyledComponentsIcon}
        style={iconStyle}
        alt='styled-component-icon'
      />
    ),
  },
  { name: "AWS", icon: <SiAmazonaws style={iconStyle} /> },
  { name: "NodeJs", icon: <FaNodeJs style={iconStyle} /> },
];
