import emailjs from "emailjs-com";
import React, { useState } from "react";

import { Spinner } from "../../components/customSpinner";
import { BottomTag, TopTag } from "../commonStyles";
import { ErrorModal, SuccessModal } from "./contactModals";
import * as Styled from "./styles";

const Contact = () => {
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const validRequiredFields =
    /.+@.+\.[A-Za-z]+$/.test(formValues.email) && formValues.message;

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSending(true);
    emailjs
      .sendForm(
        "service_edl2cl3",
        "template_9qknggi",
        event.target,
        "i-DuX4tfbAeJWWdvw"
      )
      .then(
        () => {
          setSuccessModalOpen(true);
          setFormValues({ name: "", email: "", subject: "", message: "" });
          setIsSending(false);
        },
        () => {
          setErrorModalOpen(true);
        }
      );
  };

  return (
    <Styled.ContactContainer id='contact' className='container'>
      <TopTag>{"<Contact>"}</TopTag>
      <Styled.Title>Let's work together</Styled.Title>
      <Styled.ContactForm onSubmit={handleSubmit}>
        <Styled.ContactInfoRow>
          <Styled.Name
            placeholder='Name'
            name='name'
            value={formValues.name}
            onChange={(e) =>
              setFormValues({ ...formValues, name: e.target.value })
            }
          />
          <Styled.Email
            placeholder='Email'
            name='email'
            value={formValues.email}
            onChange={(e) =>
              setFormValues({ ...formValues, email: e.target.value })
            }
          />
        </Styled.ContactInfoRow>
        <Styled.Subject
          placeholder='Subject'
          name='subject'
          value={formValues.subject}
          onChange={(e) =>
            setFormValues({ ...formValues, subject: e.target.value })
          }
        />

        <Styled.Message
          placeholder='Message'
          name='message'
          value={formValues.message}
          onChange={(e) =>
            setFormValues({ ...formValues, message: e.target.value })
          }
        />
        {!isSending ? (
          <Styled.SendButton
            className='btn'
            type='submit'
            style={{
              backgroundColor: validRequiredFields ? "" : "gray",
            }}
            disabled={!validRequiredFields}
          >
            Send
          </Styled.SendButton>
        ) : (
          <Spinner />
        )}
      </Styled.ContactForm>
      <SuccessModal
        successModalOpen={successModalOpen}
        setSuccessModalOpen={setSuccessModalOpen}
      />
      <ErrorModal
        errorModalOpen={errorModalOpen}
        setErrorModalOpen={setErrorModalOpen}
      />
      <BottomTag>{"</Contact>"}</BottomTag>
    </Styled.ContactContainer>
  );
};

export default Contact;
