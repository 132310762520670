import React from "react";

import ProfileImage from "../../assets/profile.png";
import { useIsMobile } from "../../hooks/useIsMobile";
import { BottomTag, TopTag } from "../commonStyles";
import Skills from "./Skills";
import * as Styled from "./styles";

const About = () => {
  const isMobile = useIsMobile();

  return (
    <Styled.AboutContainer id='about' className='container'>
      <TopTag>{"<about>"}</TopTag>
      <Styled.InfoContainer>
        <Styled.TextInfoContainer>
          <Styled.Description>
            As a frontend developer with 4 years of experience, I'm passionate
            about using technology to connect people and ideas. I've worked with
            clients across the United States to create highly performing apps
            with beautiful user-friendly interfaces.
          </Styled.Description>

          <Styled.Description>
            What sets me apart as a developer is my ability to collaborate
            effectively with designers, project managers, and other developers.
            I'm a strong communicator and problem-solver, always striving to
            find elegant solutions to complex challenges.
          </Styled.Description>

          <Styled.Description>
            I stay up to date with the latest frontend technologies by reading
            from Stack Overflow and Medium, and watching conferences.
          </Styled.Description>

          <Styled.Description>
            Here are some technologies I have been working with:
          </Styled.Description>

          <Skills />

          {isMobile ? (
            <Styled.ImageContainer>
              <Styled.Image src={ProfileImage} alt='Ignacio Contreres' />
            </Styled.ImageContainer>
          ) : null}
        </Styled.TextInfoContainer>
        {!isMobile ? (
          <Styled.ImageContainer>
            <Styled.Image src={ProfileImage} alt='Ignacio Contreres' />
          </Styled.ImageContainer>
        ) : null}
      </Styled.InfoContainer>
      <BottomTag>{"</about>"}</BottomTag>
    </Styled.AboutContainer>
  );
};

export default About;
